
  @red: #ff0000;
  @white: #ffffff;

  section{
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top:20px;
    margin-bottom:20px;
  }
