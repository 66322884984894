@import "_vars";
.redsection{
  color: @white;
  background: @red;
}



body {
  margin-top:150px;
}
